<template>
    <BT-Blade-Item
        @saved="journeySaved"
        bladeName="journey"
        :changeToggle="changeToggle"
        :loading="isLoading"
        navigation="journeys"
        title="Journey"
        :bladesData="bladesData"
        :trackIgnore="['movements', 'destinationCount']"
        :actualUsedHeight="112">
        <template v-slot="{ item, data }">
            <BT-Field-String
                v-model="item.journeyName"
                label="Journey Name"
                :isEditing="data.isEditing || data.isNew" />
            
            <BT-Field-Select
                v-if="!data.isNew"
                navigation="journey-templates"
                v-model="item.journeyTemplateID"
                itemText="journeyName"
                label="Template"
                alternateText="No Template"
                canSelect
                @select="data.openBlade({ bladeName: 'journey-template', data: { id: item.journeyTemplateID } })" />

            <BT-Field-Select
                navigation="users"
                v-model="item.driverUserID"
                itemText="userName"
                label="Driver"
                :isEditing="data.isEditing || data.isNew" />

            <v-list-item v-if="!data.isNew" dense>
                <v-list-item-content>
                    <v-list-item-subtitle>Status</v-list-item-subtitle>
                    <v-list-item-title v-if="item.endedOn != null">Completed</v-list-item-title>
                    <v-list-item-title v-else-if="item.startedOn != null">In Progress</v-list-item-title>
                    <v-list-item-title v-else-if="item.isReady">Ready</v-list-item-title>
                    <v-list-item-title>Not Ready</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <BT-Field-Checkbox
                v-model="item.isReady"
                label="Is Ready"
                :isEditing="data.isNew || data.isEditing" />

            <BT-Field-Select
                navigation="locations"
                v-model="item.startLocationID"
                itemText="locationName"
                label="Start Location"
                @change="val => { startLocationChanged(val, item) }"
                :isEditing="data.isNew || (data.isEditing && item.startedOn == null)" />
            
            <BT-Field-Date
                v-if="item.startedOn == null"
                v-model="item.dueStartOn"
                label="Start On"
                shortDateAndTime
                @change="val => { startTimeChanged(val, item) }"
                :isEditing="data.isEditing || data.isNew" />

            <BT-Field-Date
                v-else
                label="Started On"
                shortDateAndTime
                v-model="item.startedOn" />

            <BT-Field-Select
                navigation="locations"
                v-model="item.endLocationID"
                itemText="locationName"
                label="End Location"
                :isEditing="data.isNew || (data.isEditing && item.endedOn == null)" />
            
            <BT-Field-Date
                v-if="item.endedOn == null"
                v-model="item.dueEndOn"
                label="End On"
                shortDateAndTime
                :isEditing="data.isEditing || data.isNew" />

            <BT-Field-Date
                v-else
                label="Ended On"
                shortDateAndTime
                v-model="item.endedOn" />
            
            <v-list-item v-if="!data.isNew && !data.isEditing">
                <v-list-item-content>
                    <v-list-item-subtitle>Destinations</v-list-item-subtitle>
                    <v-list-item-title>{{ item.destinationCount | toDisplayNumber }} of {{ item.maxDestinations | toDisplayNumber }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-progress-circular
                        v-if="item.maxDestinations > 0"
                        size="30"
                        :value="(item.destinationCount / item.maxDestinations) * 100"
                        class="mr-2">
                        <span style="font-size: 9px;">{{ Math.round((item.destinationCount / item.maxDestinations) * 100) }}%</span>
                    </v-progress-circular>
                </v-list-item-action>
            </v-list-item>
            <BT-Field-Number
                v-else
                v-model.number="item.maxDestinations"
                label="Max Destinations"
                isEditing />
            
            <v-list-item v-if="!data.isNew && !data.isEditing">
                <v-list-item-content>
                    <v-list-item-subtitle>Weight (Kg)</v-list-item-subtitle>
                    <v-list-item-title>{{ item.lastWeight | toDisplayNumber }} of {{ item.maxWeight | toDisplayNumber }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-row class="mt-0">
                        <v-progress-circular
                            v-if="item.maxWeight > 0"
                            size="30"
                            :value="(item.lastWeight / item.maxWeight) * 100"
                            class="mr-2">
                            <span style="font-size: 9px;">{{ Math.round((item.lastWeight / item.maxWeight) * 100) }}%</span>
                        </v-progress-circular>
                        <v-btn icon small @click="refreshJourneyWeight(item)">
                            <v-icon small>mdi-refresh</v-icon>
                        </v-btn>
                    </v-row>
                </v-list-item-action>
            </v-list-item>
            <BT-Field-Number
                v-else
                v-model.number="item.maxWeight"
                label="Weight Capacity"
                isEditing />

            <v-list-item v-if="!data.isNew && !data.isEditing">
                <v-list-item-content>
                    <v-list-item-subtitle>Packages</v-list-item-subtitle>
                    <v-list-item-title>{{ item.lastPackages | toDisplayNumber }} of {{ item.maxPackages | toDisplayNumber }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-row class="mt-0">
                        <v-progress-circular
                            v-if="item.maxPackages > 0"
                            size="30"
                            :value="(item.lastPackages / item.maxPackages) * 100"
                            class="mr-2">
                            <span style="font-size: 9px;">{{ Math.round((item.lastPackages / item.maxPackages) * 100) }}%</span>
                        </v-progress-circular>
                        <v-btn icon small @click="refreshJourneyPackages(item)">
                            <v-icon small>mdi-refresh</v-icon>
                        </v-btn>
                    </v-row>
                </v-list-item-action>
            </v-list-item>
            <BT-Field-Number
                v-else
                v-model.number="item.maxPackages"
                label="Package Count Capacity"
                isEditing />
            
            <v-divider />
            
            <BT-Blade-Button
                v-if="!data.isNew && isJourneyHub"
                :bladesData="bladesData"
                :id="item.id"
                bladeName="deliveries"
                label="Destinations" />
            
            <BT-Snack v-model="msg" />

            <v-divider />

        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Journey-Blade',
    components: {
        // BTDialog: () => import('~components/BT-Dialog.vue')
        //BTList: () => import('~components/BT-List.vue'),
    },
    data: function() {
        return {
            changeToggle: false,
            isLoading: false,
            msg: null,
            panelV: null,
        }
    },
    props: {
        bladesData: {
            type: Object,
            default: null
        },
        isSalesHub: {
            type: Boolean,
            default: false
        },
        isJourneyHub: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        async createFromTemplate(res) {
            var template = res.template;
            var date = res.dueDate;

            if (template == null || date == null) {
                return;
            }

            this.isLoading = true;

            var post = {
                journeyName: template.journeyName,
                journeyTemplateID: template.id,
                dueStartOn: date,
                dueEndOn: date,
                driverUserID: template.driverUserID,
                maxDestinations: template.maxDestinations,
                maxWeight: template.maxWeight,
                maxPackages: template.maxPackages,
                startLocationID: template.startLocationID,
                endLocationID: template.endLocationID
            };

            try {
                await this.$BlitzIt.store.post('journeys', post);
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.msg = 'Journey Created';
                this.isLoading = false;
            }
        },
        journeySaved(original, journey) {
            this.$emit('saved', journey);
        },
        async refreshJourneyPackages(item) {
            this.isLoading = true;

            try {
                item.lastPackages = Math.round(Number.parseFloat(await this.$BlitzIt.store.get('journeys', item.id, null, true, null, '/PackageCount')));
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.isLoading = false;
            }
        },
        async refreshJourneyWeight(item) {
            this.isLoading = true;

            try {
                item.lastWeight = Math.round(Number.parseFloat(await this.$BlitzIt.store.get('journeys', item.id, null, true, null, '/Weight')));
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.isLoading = false;
            }
        },
        sortMovements(list) {
            return list;
        },
        startLocationChanged(locationID, j) {
            if (j.endLocationID == null) {
                j.endLocationID = locationID;
            }
        },
        startTimeChanged(v, j) {
            if (j.dueEndOn == null) {
                j.dueEndOn = v;
            }
        },
    }
}
</script>